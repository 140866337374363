.v-card {
  grid-template-rows: auto 1fr;
}
.v-compare-expand {
  grid-column-end: -1;
  grid-row: 1 / 3;
}

.rate-is-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #74a343;
}

.rate-is-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f4a335;
}

.v-card-table {
  height: 100%;
}

.v-card-table .Table__tr-heading {
  @apply border-t-2 border-b-2;
}
.card-header {
  @apply grid items-center justify-between mb-9;
  grid-template-columns: 1fr auto;
}

.card-header-section1 {
  @apply grid grid-cols-1 justify-between items-center gap-4;
}

.compare-graph {
  min-width: 0;
  min-height: 0;
}

.compare-graph-minimal svg {
  height: 350px;
}

@screen md {
  .v-card-table {
    height: calc(100%);
  }
  .card-header-section1 {
    @apply grid-cols-auto1fr;
  }
}

.rollOver-parent {
  position: relative;
  cursor: pointer;
}

.rollover-triangle {
  width: 22px;
  height: 22px;
  background: #414141 0% 0% no-repeat padding-box;
  border: 1px solid #a7a7a7;
  opacity: 1;
  position: absolute;
  z-index: 1;
  transform: rotate(45deg);
  top: -11px;
  border-bottom-color: transparent;
  border-right-color: transparent;
}

.rollover-container {
  visibility: hidden;
  width: 288px;
  background: #414141 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 10px #00000029;
  border: 1px solid #a7a7a7;
  opacity: 1;
  padding: 17px;
  position: absolute;
  z-index: 1;
  top: 150%;
  opacity: 0;
}

.rollover-content {
  text-align: left;
  font: normal normal normal 1rem/24px Noto Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.rollOver-parent:hover .rollover-container {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.rollover-container-right {
  right: -100%;
}
.rollover-container-right .rollover-triangle {
  left: calc(50% + 25px);
}
.rollover-container-2 {
  left: calc(-370% + 25px);
}
.rollover-container-3 {
  left: calc(-270% + 25px);
}

.rollover-container-3 .rollover-triangle {
  left: calc(70% + 25px);
}

.compare-chart-container {
  display: grid;
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 1 !important;
  grid-column-end: 4 !important;
}

@media (max-width: 1280px) {
  .v-card-layout {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
  .small-card-first {
    grid-column: 1/3;
  }
  .small-card-last {
    grid-column: 3/5;
  }
}
@media (max-width: 906px) {
  .small-card-first,
  .small-card-last {
    grid-column: 1/-1;
  }
}

.css-b62m3t-container {
  max-width: 300px;
}

.bar-chart > div > div > svg > g > g:nth-child(n + 4) > text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  fill: white !important;
  text-transform: capitalize;
  font-size: 18px;
}

@media (max-width: 1200px) {
  /* .map-card {
    grid-column: 1/-1;
  } */
}

.table-text {
  font: normal normal normal 1rem/16px Noto Sans;
  letter-spacing: 0px;
  color: #6f7072;
  opacity: 1;
}

.table-header-title-black {
  text-align: left;
  font: normal normal 600 22px/24px Noto Sans;
  letter-spacing: 0px;
  color: #57585a;
  opacity: 1;
}
.table-content-1-report {
  font: normal normal bold 18px/26px Noto Sans !important;
  letter-spacing: 0px;
  color: #6f7072;
}
.table-value-1-report {
  text-align: left;
  font: normal normal bold 30px/26px Noto Sans !important;
  letter-spacing: 0px;
  color: #3b3b3b;
}
.insights-card {
  grid-row-start: 3;
  grid-row-end: 3;
  grid-column-start: 3;
  grid-column-end: 3;
}
.insights-table {
  border-top: 2px solid #e6e7e9;
  border-bottom: 2px solid #e6e7e9;
  height: 100%;
}
.insights-field {
  color: #6f7072;
  font: normal normal 600 1.375rem/29px Noto Sans;
  margin-bottom: 0.6875rem;
}
.insights-title {
  font: normal normal 600 1.375rem/30px Noto Sans;
  letter-spacing: 0px;
  color: #414141;
  opacity: 1;
  margin-bottom: 0.6875rem;
}
.insights-field-value {
  font: normal normal normal 1.375rem/29px Noto Sans;
  letter-spacing: 0px;
  color: #6f7072;
}
.insights-header {
  font: normal normal 600 1.375rem/29px Noto Sans;
  margin-bottom: 0.4375rem;
}

@media (max-width: 1280px) {
  .large-card {
    grid-column: 1/-1 !important;
  }
}
.local-temperature-container {
  grid-row-start: 3;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 2;
}

.grid-template-columns12 {
  grid-template-columns: 2fr 3fr;
}
.compare-card-header {
  margin-bottom: 1.75rem !important;
  grid-template-columns: 2fr 3fr !important;
}
.status-icon {
  width: 3rem;
}
@media (max-width: 1280px) {
  .grid-template-columns12 {
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr;
  }
  .compare-sensor-header {
    padding-left: 0 !important;
    margin-top: 2rem;
  }
}
@media (max-width: 768px) {
  .v-dashboard-bottom {
    z-index: 900000000;
  }
  .sensor-list-dropdown {
    display: none !important;
  }
}

@media (max-width: 637px) {
  .compare-list-dropdown {
    display: none !important;
  }
}
