.v-sidebar-desktop {
}

.v-sidebar-nav {
  height: calc(100% - 96px);
}

.v-hamburger-container {
  max-width: 100px;
}
.v-hamburger-container > div {
  height: 2px;
}

.icon-default-side .a,
.icon-default-side {
  fill: #6f7072;
  color: #6f7072;
}

.icon-active-side .a,
.icon-active-side {
  fill: white;
  color: white;
}

.icon-active-bottom .a,
.icon-active-bottom {
  fill: #6f7072;
  color: #6f7072;
}

@screen md {
  .v-header-inputs {
    grid-template-columns: minmax(0, 400px) auto;
  }
}

.v-header-top-nav {
  @apply items-center justify-between  w-full;
  padding: 2.5rem !important;
  max-width: 100%;
  width: 100%;
}

.my-table table th {
  padding: 16px 0;
  height: 56px;
}

.my-table table td {
  padding: 10.5px 0;
}
.my-table table {
  border-bottom: 2px solid #e4e5e7;
  height: 100%;
}
.my-table tbody {
  height: 100%;
  overflow-y: scroll;
}
/* .my-table thead, */
/* .my-table tbody {
  display: block;
  width: 100%;
} */
@media (max-width: 1280px) {
  .current-date-time {
    margin-right: 25px !important;
  }
  .current-time {
    margin-left: 12px !important;
  }
  .current-date {
    margin-right: 12px !important;
  }
}
@media (max-width: 1110px) {
  .current-date-time {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .small-screen-hide {
    display: none !important;
  }
  .small-screen-fill {
    width: 100% !important;
    margin: 25px;
  }
}
.header-container {
  grid-template-columns: 1fr 2fr;
}
