.p-typography,
.h-typography {
  /*@apply text-textGrey;*/
}

.v-btn {
  @apply flex items-center font-bold justify-center px-6 py-2.5 text-white uppercase bg-blue rounded-lg;
  min-height: 44px;
  min-width: 114px;
}

.v-rounded-img {
  @apply rounded-full w-12 h-12 border border-black object-cover;
  min-width: 3rem;
  min-height: 3rem;
}

.v-input {
  @apply flex items-center p-2.5 px-5 border rounded-lg border-black w-full max-h-11;
  border: 1px solid #707070 !important;
}

.v-input-input {
  @apply w-full outline-none;
}

@screen md {
  .v-rounded-img {
    @apply w-14 h-14;
    min-width: 3.5rem;
    min-height: 3.5rem;
  }
}

.css-b62m3t-container {
  width: 100%;
}

.select-button {
  height: 47;
  display: inline-flex;
  align-items: center;
}
