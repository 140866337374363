.select-button {
  height: 47px;
  display: inline-flex;
  align-items: center;
}
.drop-down-item-active {
  background-color: rgba(0, 0, 0, 0.1);
}
.drop-down-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
