/*purgecss start ignore*/

.p-typography,
.h-typography {
  /*@apply text-textGrey;*/
}

.v-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-border-radius: 0.5rem;
     -moz-border-radius: 0.5rem;
          border-radius: 0.5rem;
  background-color: var(--blue);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  font-weight: 700;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  min-height: 44px;
  min-width: 114px;
}

.v-rounded-img {
  height: 3rem;
  width: 3rem;
  -webkit-border-radius: 9999px;
     -moz-border-radius: 9999px;
          border-radius: 9999px;
  border-width: 1px;
  border-color: var(--black);
  -o-object-fit: cover;
     object-fit: cover;
  min-width: 3rem;
  min-height: 3rem;
}

.v-input {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  max-height: 2.75rem;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-border-radius: 0.5rem;
     -moz-border-radius: 0.5rem;
          border-radius: 0.5rem;
  border-width: 1px;
  border-color: var(--black);
  padding: 0.625rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border: 1px solid #707070 !important;
}

.v-input-input {
  width: 100%;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 768px) {
  .v-rounded-img {
    height: 3.5rem;
    width: 3.5rem;
    min-width: 3.5rem;
    min-height: 3.5rem;
  }
}

.css-b62m3t-container {
  width: 100%;
}

.select-button {
  height: 47;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
}

/*purgecss end ignore*/

/* purgecss start ignore */

.v-sidebar-desktop {
}

.v-sidebar-nav {
  height: -webkit-calc(100% - 96px);
  height: -moz-calc(100% - 96px);
  height: calc(100% - 96px);
}

.v-hamburger-container {
  max-width: 100px;
}

.v-hamburger-container > div {
  height: 2px;
}

.icon-default-side .a,
.icon-default-side {
  fill: #6f7072;
  color: #6f7072;
}

.icon-active-side .a,
.icon-active-side {
  fill: white;
  color: white;
}

.icon-active-bottom .a,
.icon-active-bottom {
  fill: #6f7072;
  color: #6f7072;
}

@media (min-width: 768px) {
  .v-header-inputs {
    grid-template-columns: minmax(0, 400px) auto;
  }
}

.v-header-top-nav {
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 2.5rem !important;
  max-width: 100%;
  width: 100%;
}

.my-table table th {
  padding: 16px 0;
  height: 56px;
}

.my-table table td {
  padding: 10.5px 0;
}

.my-table table {
  border-bottom: 2px solid #e4e5e7;
  height: 100%;
}

.my-table tbody {
  height: 100%;
  overflow-y: scroll;
}

/* .my-table thead, */

/* .my-table tbody {
  display: block;
  width: 100%;
} */

@media (max-width: 1280px) {
  .current-date-time {
    margin-right: 25px !important;
  }

  .current-time {
    margin-left: 12px !important;
  }

  .current-date {
    margin-right: 12px !important;
  }
}

@media (max-width: 1110px) {
  .current-date-time {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .small-screen-hide {
    display: none !important;
  }

  .small-screen-fill {
    width: 100% !important;
    margin: 25px;
  }
}

.header-container {
  grid-template-columns: 1fr 2fr;
}

/* purgecss end ignore */

/* purgecss start ignore */

.v-home-layout {
}

.v-dashboard-bottom {
  position: fixed;
  bottom: 0px;
  height: 4rem;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.v-dashboard-content {
  overflow: scroll;
  height: -webkit-calc(100vh - 97px);
  height: -moz-calc(100vh - 97px);
  height: calc(100vh - 97px);
  background-color: var(--bgGrey);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 5rem;
}

@media (min-width: 768px) {
  .v-dashboard-content {
    padding-bottom: 0px;
  }
}

@media (max-width: 1280px) {
  .container {
    max-width: 100% !important;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.v-dashboard-bottom {
  position: fixed;
  bottom: 0px;
  height: 4rem;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.v-dashboard-content {
  overflow: scroll;
  height: -webkit-calc(100vh - 97px);
  height: -moz-calc(100vh - 97px);
  height: calc(100vh - 97px);
  background-color: var(--bgGrey);
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 5rem;
}

@media (min-width: 768px) {
  .v-dashboard-content {
    padding-bottom: 0px;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.v-card {
  grid-template-rows: auto 1fr;
}

.v-compare-expand {
  grid-column-end: -1;
  grid-row: 1 / 3;
}

.rate-is-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #74a343;
}

.rate-is-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f4a335;
}

.v-card-table {
  height: 100%;
}

.v-card-table .Table__tr-heading {
  border-top-width: 2px;
  border-bottom-width: 2px;
}

.card-header {
  margin-bottom: 2.25rem;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  grid-template-columns: 1fr auto;
}

.card-header-section1 {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 1rem;
}

.compare-graph {
  min-width: 0;
  min-height: 0;
}

.compare-graph-minimal svg {
  height: 350px;
}

@media (min-width: 768px) {
  .v-card-table {
    height: -webkit-calc(100%);
    height: -moz-calc(100%);
    height: calc(100%);
  }

  .card-header-section1 {
    grid-template-columns: auto 1fr;
  }
}

@media (max-width: 1280px) {
  .v-card-layout {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .small-card-first {
    grid-column: 1/3;
  }

  .small-card-last {
    grid-column: 3/5;
  }
}

@media (max-width: 906px) {
  .small-card-first,
  .small-card-last {
    grid-column: 1/-1;
  }
}

.css-b62m3t-container {
  max-width: 300px;
}

.bar-chart > div > div > svg > g > g:nth-child(n + 4) > text {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-text-orientation: mixed;
  text-orientation: mixed;
  fill: white !important;
  text-transform: capitalize;
  font-size: 18px;
}

@media (max-width: 1200px) {
  /* .map-card {
    grid-column: 1/-1;
  } */
}

.table-text {
  font: normal normal normal 1rem/16px Noto Sans;
  letter-spacing: 0px;
  color: #6f7072;
  opacity: 1;
}

.table-header-title-black {
  text-align: left;
  font: normal normal 600 22px/24px Noto Sans;
  letter-spacing: 0px;
  color: #57585a;
  opacity: 1;
}

.table-content-1-report {
  font: normal normal bold 18px/26px Noto Sans !important;
  letter-spacing: 0px;
  color: #6f7072;
}

.table-value-1-report {
  text-align: left;
  font: normal normal bold 30px/26px Noto Sans !important;
  letter-spacing: 0px;
  color: #3b3b3b;
}

@media (max-width: 1280px) {
  .large-card {
    grid-column: 1/-1 !important;
  }

  .small-card {
  }
}

.insight-heading,
.insignt-location {
  text-align: left;
  font: normal normal 600 1.3rem/29px Noto Sans;
  letter-spacing: 0px;
}

.sensor-list {
  grid-row-start: 2;
  grid-row-end: 4;
}

.status-badge-container {
  border: 1px solid #e2e2e2;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  opacity: 1;
  padding: 0 27px;
}

.status-badge-title {
  text-align: left;
  font: normal normal 600 16px/21px Noto Sans;
  letter-spacing: 0px;
  color: #6f7072;
  opacity: 1;
  margin-right: 10px;
}

.status-badge-icon {
  width: 3rem;
}

@media (max-width: 1628px) {
  .sensor-list {
    grid-row-start: 3;
    grid-row-end: 5;
  }
}

@media (max-width: 1280px) {
  .sensor-list {
    grid-row-start: 5;
    grid-row-end: 7;
  }
}

@media (max-width: 1280px) {
  .compare-graph,
  .compare-graph-minimal svg {
    min-height: 300px !important;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.v-card {
  grid-template-rows: auto 1fr;
}

.v-compare-expand {
  grid-column-end: -1;
  grid-row: 1 / 3;
}

.rate-is-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #74a343;
}

.rate-is-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f4a335;
}

.v-card-layout {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.5rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

@media (min-width: 1280px) {
  .v-card-layout {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1536px) {
  .v-card-layout {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.v-card-layout {
  grid-template-rows: 480px 420px 420px;
}

/* .v-card-layout--has-expansion {
  grid-template-rows: repeat(6, 420px);
} */

/* @media(max-width:1284px){

} */

.v-card-table {
  height: 100%;
}

.v-card-table .Table__tr-heading {
  border-top-width: 2px;
  border-bottom-width: 2px;
}

.card-header {
  margin-bottom: 2.25rem;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  grid-template-columns: 1fr auto;
}

.card-header-section1 {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 1rem;
}

.compare-graph {
  min-width: 0;
  min-height: 0;
}

.compare-graph-minimal svg {
  height: 350px;
}

@media (min-width: 768px) {
  .v-card-table {
    height: -webkit-calc(100%);
    height: -moz-calc(100%);
    height: calc(100%);
  }

  .card-header-section1 {
    grid-template-columns: auto 1fr;
  }
}

.css-b62m3t-container {
  max-width: 300px;
}

.bar-chart > div > div > svg > g > g:nth-child(n + 4) > text {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-text-orientation: mixed;
  text-orientation: mixed;
  fill: white !important;
  text-transform: capitalize;
  font-size: 18px;
}

.table-text {
  font: normal normal normal 1rem/16px Noto Sans;
  letter-spacing: 0px;
  color: #6f7072;
  opacity: 1;
}

@media (max-width: 1280px) {
  .container {
    max-width: 100% !important;
  }
}

.sensor-level-container {
  grid-row-start: 2;
  grid-row-end: 4;
  grid-column-start: 2 !important;
  grid-column-end: 4 !important;
}

@media (max-width: 1628px) {
  .sensor-level-container {
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 1 !important;
    grid-column-end: 4 !important;
  }
}

@media (max-width: 1280px) {
  .sensor-level-container {
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 1 !important;
    grid-column-end: 4 !important;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.v-card {
  grid-template-rows: auto 1fr;
}

.v-compare-expand {
  grid-column-end: -1;
  grid-row: 1 / 3;
}

.rate-is-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #74a343;
}

.rate-is-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f4a335;
}

.v-card-table {
  height: 100%;
}

.v-card-table .Table__tr-heading {
  border-top-width: 2px;
  border-bottom-width: 2px;
}

.card-header {
  margin-bottom: 2.25rem;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  grid-template-columns: 1fr auto;
}

.card-header-section1 {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 1rem;
}

.compare-graph {
  min-width: 0;
  min-height: 0;
}

.compare-graph-minimal svg {
  height: 350px;
}

@media (min-width: 768px) {
  .v-card-table {
    height: -webkit-calc(100%);
    height: -moz-calc(100%);
    height: calc(100%);
  }

  .card-header-section1 {
    grid-template-columns: auto 1fr;
  }
}

.rollOver-parent {
  position: relative;
  cursor: pointer;
}

.rollover-triangle {
  width: 22px;
  height: 22px;
  background: #414141 0% 0% no-repeat padding-box;
  border: 1px solid #a7a7a7;
  opacity: 1;
  position: absolute;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -11px;
  border-bottom-color: transparent;
  border-right-color: transparent;
}

.rollover-container {
  visibility: hidden;
  width: 288px;
  background: #414141 0% 0% no-repeat padding-box;
  -webkit-box-shadow: 3px 3px 10px #00000029;
  -moz-box-shadow: 3px 3px 10px #00000029;
  box-shadow: 3px 3px 10px #00000029;
  border: 1px solid #a7a7a7;
  opacity: 1;
  padding: 17px;
  position: absolute;
  z-index: 1;
  top: 150%;
  opacity: 0;
}

.rollover-content {
  text-align: left;
  font: normal normal normal 1rem/24px Noto Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.rollOver-parent:hover .rollover-container {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
}

.rollover-container-right {
  right: -100%;
}

.rollover-container-right .rollover-triangle {
  left: -webkit-calc(50% + 25px);
  left: -moz-calc(50% + 25px);
  left: calc(50% + 25px);
}

.rollover-container-2 {
  left: -webkit-calc(-370% + 25px);
  left: -moz-calc(-370% + 25px);
  left: calc(-370% + 25px);
}

.rollover-container-3 {
  left: -webkit-calc(-270% + 25px);
  left: -moz-calc(-270% + 25px);
  left: calc(-270% + 25px);
}

.rollover-container-3 .rollover-triangle {
  left: -webkit-calc(70% + 25px);
  left: -moz-calc(70% + 25px);
  left: calc(70% + 25px);
}

@media (max-width: 1280px) {
  .v-card-layout {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .small-card-first {
    grid-column: 1/3;
  }

  .small-card-last {
    grid-column: 3/5;
  }
}

@media (max-width: 906px) {
  .small-card-first,
  .small-card-last {
    grid-column: 1/-1;
  }
}

.css-b62m3t-container {
  max-width: 300px;
}

.bar-chart > div > div > svg > g > g:nth-child(n + 4) > text {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-text-orientation: mixed;
  text-orientation: mixed;
  fill: white !important;
  text-transform: capitalize;
  font-size: 18px;
}

@media (max-width: 1200px) {
  /* .map-card {
    grid-column: 1/-1;
  } */
}

.table-text {
  font: normal normal normal 1rem/16px Noto Sans;
  letter-spacing: 0px;
  color: #6f7072;
  opacity: 1;
}

.table-header-title-black {
  text-align: left;
  font: normal normal 600 22px/24px Noto Sans;
  letter-spacing: 0px;
  color: #57585a;
  opacity: 1;
}

.table-content-1-report {
  font: normal normal bold 18px/26px Noto Sans !important;
  letter-spacing: 0px;
  color: #6f7072;
}

.table-value-1-report {
  text-align: left;
  font: normal normal bold 30px/26px Noto Sans !important;
  letter-spacing: 0px;
  color: #3b3b3b;
}

.insights-table {
  border-top: 2px solid #e6e7e9;
  border-bottom: 2px solid #e6e7e9;
  height: 100%;
}

.insights-field {
  color: #6f7072;
  font: normal normal 600 1.375rem/29px Noto Sans;
  margin-bottom: 0.6875rem;
}

.insights-title {
  font: normal normal 600 1.375rem/30px Noto Sans;
  letter-spacing: 0px;
  color: #414141;
  opacity: 1;
  margin-bottom: 0.6875rem;
}

.insights-field-value {
  font: normal normal normal 1.375rem/29px Noto Sans;
  letter-spacing: 0px;
  color: #6f7072;
}

.insights-header {
  font: normal normal 600 1.375rem/29px Noto Sans;
  margin-bottom: 0.4375rem;
}

@media (max-width: 1280px) {
  .large-card {
    grid-column: 1/-1 !important;
  }
}

.grid-template-columns12 {
  grid-template-columns: 2fr 3fr;
}

.compare-card-header {
  margin-bottom: 1.75rem !important;
  grid-template-columns: 2fr 3fr !important;
}

.status-icon {
  width: 3rem;
}

@media (max-width: 1280px) {
  .grid-template-columns12 {
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr;
  }

  .compare-sensor-header {
    padding-left: 0 !important;
    margin-top: 2rem;
  }
}

@media (max-width: 768px) {
  .v-dashboard-bottom {
    z-index: 900000000;
  }

  .sensor-list-dropdown {
    display: none !important;
  }
}

@media (max-width: 637px) {
  .compare-list-dropdown {
    display: none !important;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.compare-chart-container {
  grid-row-start: 1 !important;
  grid-row-end: 1 !important;
  grid-column-end: 1 !important;
  grid-column-end: 4 !important;
}

/* purgecss end ignore */

/* purgecss start ignore */

.v-sidebar-desktop {
}

.v-sidebar-nav {
  height: -webkit-calc(100% - 96px);
  height: -moz-calc(100% - 96px);
  height: calc(100% - 96px);
}

.v-hamburger-container {
  max-width: 100px;
}

.v-hamburger-container > div {
  height: 2px;
}

.icon-default-side .a,
.icon-default-side {
  fill: #6f7072;
  color: #6f7072;
}

.icon-active-side .a,
.icon-active-side {
  fill: white;
  color: white;
}

.icon-active-bottom .a,
.icon-active-bottom {
  fill: #6f7072;
  color: white;
}

@media (min-width: 768px) {
  .v-header-inputs {
    grid-template-columns: minmax(0, 400px) auto;
  }
}

.v-header-top-nav {
  width: 100%;
}

@media (min-width: 640px) {
  .v-header-top-nav {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .v-header-top-nav {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .v-header-top-nav {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .v-header-top-nav {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .v-header-top-nav {
    max-width: 1536px;
  }
}

.v-header-top-nav {
  margin: auto;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@media (min-width: 640px) {
  .v-header-top-nav {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.my-table table th {
  padding: 16px 0;
  height: 56px;
}

.my-table table td {
  padding: 10.5px 0;
}

.my-table table {
  border-bottom: 2px solid #e4e5e7;
  height: 100%;
}

.my-table tbody {
  height: 100%;
  overflow-y: scroll;
}

/* .my-table thead, */

/* .my-table tbody {
  display: block;
  width: 100%;
} */

.tool-tip-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  -webkit-box-shadow: 3px 3px 10px #00000029;
  -moz-box-shadow: 3px 3px 10px #00000029;
  box-shadow: 3px 3px 10px #00000029;
  border: 1px solid #a7a7a7;
  text-align: center;
  color: #000002;
  padding: 5px;
  position: relative;
}

.tool-tip-time {
  text-align: center;
  font: normal normal normal 10px/10px Noto Sans;
  letter-spacing: 0px;
  color: #000002;
  padding-bottom: 5px;
}

.tool-tip-value {
  text-align: center;
  font: normal normal 600 9px/10px Noto Sans;
  letter-spacing: 0px;
  color: #000002;
}

.tool-tip-container:after {
  position: absolute;
  content: '';
  z-index: 500000;
  width: 7px;
  height: 7px;
  background-color: red;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  left: -webkit-calc(50% - 3.5px);
  left: -moz-calc(50% - 3.5px);
  left: calc(50% - 3.5px);
  bottom: -3.5px;
  background-color: white;
  border-right: 1px solid #a7a7a7;
  border-bottom: 1px solid #a7a7a7;
}

#bar-chart-container {
  height: 92%;
  width: 100%;
}

.drop-down-box {
  border: 1px solid #707070;
}

.barchart-tooltip {
  padding: 12;
  color: #000002;
  background: #ffffff 0% 0% no-repeat padding-box;
  -webkit-box-shadow: 3px 3px 10px #00000029;
  -moz-box-shadow: 3px 3px 10px #00000029;
  box-shadow: 3px 3px 10px #00000029;
  border: 1px solid #a7a7a7;
  text-align: center;
  max-width: 200px;
  padding: 8px 14px;
  position: relative;
}

.barchart-tooltip-bottom {
  width: 30px;
  height: 30px;
  background-color: white;
  position: absolute;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  left: -webkit-calc(50% - 15px);
  left: -moz-calc(50% - 15px);
  left: calc(50% - 15px);
  top: -webkit-calc(100% - 15px);
  top: -moz-calc(100% - 15px);
  top: calc(100% - 15px);
  border-bottom: 1px solid #a7a7a7;
  border-right: 1px solid #a7a7a7;
  z-index: 0;
}

/* purgecss end ignore */

/* purgecss start ignore */

.v-card {
  grid-template-rows: auto 1fr;
}

.v-compare-expand {
  grid-column-end: -1;
  grid-row: 1 / 3;
}

.rate-is-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #74a343;
}

.rate-is-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f4a335;
}

.v-card-table {
  height: 100%;
}

.v-card-table .Table__tr-heading {
  border-top-width: 2px;
  border-bottom-width: 2px;
}

.card-header {
  margin-bottom: 2.25rem;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  grid-template-columns: 1fr auto;
}

.card-header-section1 {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 1rem;
}

.compare-graph {
  min-width: 0;
  min-height: 0;
}

.compare-graph-minimal svg {
  height: 350px;
}

@media (min-width: 768px) {
  .v-card-table {
    height: -webkit-calc(100%);
    height: -moz-calc(100%);
    height: calc(100%);
  }

  .card-header-section1 {
    grid-template-columns: auto 1fr;
  }
}

.rollOver-parent {
  position: relative;
  cursor: pointer;
}

.rollover-triangle {
  width: 22px;
  height: 22px;
  background: #414141 0% 0% no-repeat padding-box;
  border: 1px solid #a7a7a7;
  opacity: 1;
  position: absolute;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -11px;
  border-bottom-color: transparent;
  border-right-color: transparent;
}

.rollover-container {
  visibility: hidden;
  width: 288px;
  background: #414141 0% 0% no-repeat padding-box;
  -webkit-box-shadow: 3px 3px 10px #00000029;
  -moz-box-shadow: 3px 3px 10px #00000029;
  box-shadow: 3px 3px 10px #00000029;
  border: 1px solid #a7a7a7;
  opacity: 1;
  padding: 17px;
  position: absolute;
  z-index: 1;
  top: 150%;
  opacity: 0;
}

.rollover-content {
  text-align: left;
  font: normal normal normal 1rem/24px Noto Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.rollOver-parent:hover .rollover-container {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
}

.rollover-container-right {
  right: -100%;
}

.rollover-container-right .rollover-triangle {
  left: -webkit-calc(50% + 25px);
  left: -moz-calc(50% + 25px);
  left: calc(50% + 25px);
}

.rollover-container-2 {
  left: -webkit-calc(-370% + 25px);
  left: -moz-calc(-370% + 25px);
  left: calc(-370% + 25px);
}

.rollover-container-3 {
  left: -webkit-calc(-270% + 25px);
  left: -moz-calc(-270% + 25px);
  left: calc(-270% + 25px);
}

.rollover-container-3 .rollover-triangle {
  left: -webkit-calc(70% + 25px);
  left: -moz-calc(70% + 25px);
  left: calc(70% + 25px);
}

.compare-chart-container {
  display: grid;
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 1 !important;
  grid-column-end: 4 !important;
}

@media (max-width: 1280px) {
  .v-card-layout {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .small-card-first {
    grid-column: 1/3;
  }

  .small-card-last {
    grid-column: 3/5;
  }
}

@media (max-width: 906px) {
  .small-card-first,
  .small-card-last {
    grid-column: 1/-1;
  }
}

.css-b62m3t-container {
  max-width: 300px;
}

.bar-chart > div > div > svg > g > g:nth-child(n + 4) > text {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-text-orientation: mixed;
  text-orientation: mixed;
  fill: white !important;
  text-transform: capitalize;
  font-size: 18px;
}

@media (max-width: 1200px) {
  /* .map-card {
    grid-column: 1/-1;
  } */
}

.table-text {
  font: normal normal normal 1rem/16px Noto Sans;
  letter-spacing: 0px;
  color: #6f7072;
  opacity: 1;
}

.table-header-title-black {
  text-align: left;
  font: normal normal 600 22px/24px Noto Sans;
  letter-spacing: 0px;
  color: #57585a;
  opacity: 1;
}

.table-content-1-report {
  font: normal normal bold 18px/26px Noto Sans !important;
  letter-spacing: 0px;
  color: #6f7072;
}

.table-value-1-report {
  text-align: left;
  font: normal normal bold 30px/26px Noto Sans !important;
  letter-spacing: 0px;
  color: #3b3b3b;
}

.insights-card {
  grid-row-start: 3;
  grid-row-end: 3;
  grid-column-start: 3;
  grid-column-end: 3;
}

.insights-table {
  border-top: 2px solid #e6e7e9;
  border-bottom: 2px solid #e6e7e9;
  height: 100%;
}

.insights-field {
  color: #6f7072;
  font: normal normal 600 1.375rem/29px Noto Sans;
  margin-bottom: 0.6875rem;
}

.insights-title {
  font: normal normal 600 1.375rem/30px Noto Sans;
  letter-spacing: 0px;
  color: #414141;
  opacity: 1;
  margin-bottom: 0.6875rem;
}

.insights-field-value {
  font: normal normal normal 1.375rem/29px Noto Sans;
  letter-spacing: 0px;
  color: #6f7072;
}

.insights-header {
  font: normal normal 600 1.375rem/29px Noto Sans;
  margin-bottom: 0.4375rem;
}

@media (max-width: 1280px) {
  .large-card {
    grid-column: 1/-1 !important;
  }
}

.local-temperature-container {
  grid-row-start: 3;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 2;
}

.grid-template-columns12 {
  grid-template-columns: 2fr 3fr;
}

.compare-card-header {
  margin-bottom: 1.75rem !important;
  grid-template-columns: 2fr 3fr !important;
}

.status-icon {
  width: 3rem;
}

@media (max-width: 1280px) {
  .grid-template-columns12 {
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr;
  }

  .compare-sensor-header {
    padding-left: 0 !important;
    margin-top: 2rem;
  }
}

@media (max-width: 768px) {
  .v-dashboard-bottom {
    z-index: 900000000;
  }

  .sensor-list-dropdown {
    display: none !important;
  }
}

@media (max-width: 637px) {
  .compare-list-dropdown {
    display: none !important;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.select-button {
  height: 47px;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
}

.drop-down-item-active {
  background-color: rgba(0, 0, 0, 0.1);
}

.drop-down-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* purgecss end ignore */

/* purgecss start ignore */

.v-auth {
  background: white;
  display: grid;
  height: 100vh;
}

.v-login-card {
  margin: auto;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: unset;
  -moz-box-shadow: unset;
  box-shadow: unset;
  width: 100%;
}

.v-auth-separator {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.v-auth-separator::after,
.v-auth-separator::before {
  content: '';
}

.v-auth-separator::after, .v-auth-separator::before {
  height: 1px;
  width: 100%;
  background-color: var(--textGrey);
}

@media (min-width: 768px) {
  .v-login-card {
    -webkit-box-shadow: unset;
    -moz-box-shadow: unset;
    box-shadow: unset;
    max-width: 400px;
    position: relative;
    width: 100%;
    -webkit-border-radius: 1.5rem;
       -moz-border-radius: 1.5rem;
            border-radius: 1.5rem;
    padding: 2rem;
    --tw-shadow: 4px 4px 6px #0000001D;
    -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
       -moz-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .v-auth {
    background: url(/static/media/auth-background.ec54ccfd.png) no-repeat center;
    -moz-background-size: cover;
    background-size: cover;
  }
}

.error {
  font-size: 12px;
  color: red;
  text-align: center;
  height: 20px;
}

/* purgecss end ignore */

/* purgecss start ignore */

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Noto Sans', sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* purgecss end ignore */

/* purgecss start ignore */

:root {
  --textSoft: #b2b2b2;
  --textGrey: #6f7072;
  --textBlack: #57585a;
  --blue: #0188b2;
  --black: #707070;
  --green: #74a343;
  --darkGreen: #019293;
  --orange: #f4a335;
  --red: #c83d5c;
  --grey: #d8d8d8;
  --bgGrey: #ebecee;
  --bgBlack: #414141;
}

#root {
  height: 100vh;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 8px;
}

.cloud {
  position: relative;
  fill: rgb(178, 178, 178);
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  left: -20px;
  top: 16px;
}

.svg-chlorine-active .a {
  fill: #0188b2;
  fill: var(--blue);
}

.svg-turbidity-active .a {
  fill: #019293;
}

.svg-temperature-active .a {
  fill: #c83d5c;
  fill: var(--red);
}

.svg-ph-active .a {
  fill: #f4a335;
  fill: var(--orange);
}

.text-normal-blue {
  text-align: left;
  font: normal normal 600 1.5rem/33px Noto Sans !important;
  letter-spacing: 0px;
  color: #0087b2;
  text-transform: uppercase;
  opacity: 1;
}

.text-thin-blue {
  text-align: left;
  font: normal normal 600 1.18rem/19px Noto Sans;
  font-weight: 500;
  letter-spacing: 0px;
  color: #0087b2;
  opacity: 1;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.text-thin-black {
  text-align: left;
  font: normal normal 600 1.18rem/19px Noto Sans;
  font-weight: 500;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.drop-down {
}

.drop-down:active,
.drop-down:focus,
.drop-down:focus-visible {
  --tw-ring-opacity: 0;
  --tw-ring-offset-color: transparent;
}

.select-border-color {
  border: 1px solid #707070;
}

.select-border-radius {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.black-drop-down .text-thin-blue {
  text-align: left;
  font: normal normal normal 16px/22px Noto Sans;
  letter-spacing: 0px;
  color: #6e6f71;
  opacity: 1;
}

.black-text .text-thin-blue {
  color: #000;
}

.no-table-border-bottom table {
  border-bottom: transparent;
}

.svg-chlorine-active {
  color: #0087b2;
}

.svg-ph-active {
  color: #d9683c;
}

.svg-temperature-active {
  color: #c83d5c;
  color: var(--red);
}

.svg-turbidity-active {
  color: #019293;
}

.svg-conductivity-active {
  color: #009396;
  fill: #009396;
}

@media (max-width: 1536px) {
  html {
    font-size: 90%;
  }
}

@media (max-width: 1378px) {
  html {
    font-size: 80%;
  }
}

/* purgecss end ignore */
